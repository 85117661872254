exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-comp-tsx": () => import("./../../../src/pages/contact/comp.tsx" /* webpackChunkName: "component---src-pages-contact-comp-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cultural-index-tsx": () => import("./../../../src/pages/cultural/index.tsx" /* webpackChunkName: "component---src-pages-cultural-index-tsx" */),
  "component---src-pages-database-index-tsx": () => import("./../../../src/pages/database/index.tsx" /* webpackChunkName: "component---src-pages-database-index-tsx" */),
  "component---src-pages-exhibition-index-tsx": () => import("./../../../src/pages/exhibition/index.tsx" /* webpackChunkName: "component---src-pages-exhibition-index-tsx" */),
  "component---src-pages-guide-index-tsx": () => import("./../../../src/pages/guide/index.tsx" /* webpackChunkName: "component---src-pages-guide-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-policy-index-tsx": () => import("./../../../src/pages/policy/index.tsx" /* webpackChunkName: "component---src-pages-policy-index-tsx" */),
  "component---src-pages-publications-index-tsx": () => import("./../../../src/pages/publications/index.tsx" /* webpackChunkName: "component---src-pages-publications-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-database-tsx": () => import("./../../../src/templates/database.tsx" /* webpackChunkName: "component---src-templates-database-tsx" */),
  "component---src-templates-exhibition-tsx": () => import("./../../../src/templates/exhibition.tsx" /* webpackChunkName: "component---src-templates-exhibition-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

